import { yupResolver } from "@hookform/resolvers/yup"
import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Link, useSearchParams } from "react-router-dom"
import FadeLoader from "react-spinners/FadeLoader"
import * as yup from "yup"
import { Save } from "../../assets/svgr"
import { UIButton, UIButtonIcon, UIImgUpload, UIInputWithRegister, UISelect } from "../../components/UI"
import UIHtmlTooltip from "../../components/UI/UIHtmlTooltip"
import { checkEnv } from "../../lib"
import { BLACK_COLOR, ERROR_COLOR, WHITE } from "../../styles/colors"
import { CardLangItem } from "./CardLangItem"
import { CardStepItem } from "./CardStepItem"

export const WidgetManager = ({
  manual,
  updateData,
  fetchBrandsByLabel,
  brandByIdBrand,
  loading,
  setLoading,
  saveSuccess,
  publishedManual,
  themeStyles,
  addNewStep,
}) => {
  // states --------------------------------------------------------------------
  const [newPicture, setNewPicture] = useState()

  // hooks ---------------------------------------------------------------------
  const { t } = useTranslation("managerPage")
  const [brandSelected, setBrandSelected] = useState({ value: "", label: "" })
  const config = useMemo(() => checkEnv(), [])

  const [params] = useSearchParams()
  // init style ----------------------------------------------------------------
  const styles = getStyles({
    theme: themeStyles,
  })

  const schema = yup.object().shape({
    productName: yup.string().required(),
    nbUser: yup.number().min(1, t("errors.error_nb_user")).max(9, t("errors.error_nb_user")),
    time: yup.number().min(5, t("errors.error_time")).max(240, t("errors.error_time")),
    brandId: yup.string().required(),
  })

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    mode: "onSubmit",
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    fetchBrandsByLabel({ variables: { label: "" } })
  }, [])

  useLayoutEffect(() => {
    if (manual) {
      if (!watch("productName")) setValue("productName", manual?.productByIdProduct?.name)
      if (!watch("nbUser")) setValue("nbUser", manual?.numberOfPeopleNeeded)
      if (!watch("time")) setValue("time", manual?.assemblageDuration?.minutes)
      if (!brandSelected.value) {
        setBrandSelected({
          label: manual?.productByIdProduct?.brandByIdBrand?.label,
          value: manual?.productByIdProduct?.brandByIdBrand?.id,
        })
      }
      if (!watch("brandId")) setValue("brandId", manual?.productByIdProduct?.brandByIdBrand?.id)
    }
  }, [manual])

  const onSubmit = data => {
    setLoading(true)
    updateData({
      ...data,
      manualId: manual?.id,
      productId: manual?.productByIdProduct?.id,
      file: newPicture,
    })
  }

  const onChangeCoverImage = useCallback(async imageList => {
    setNewPicture({
      filename: null,
      data: null,
      loading: true,
      url: null,
      error: false,
    })

    if (imageList[0].file) {
      const file = imageList[0].file
      if (file) {
        const filename = imageList[0].file.name
        setNewPicture(prev => ({ ...prev, filename }))

        // test width == height
        const img = new Image()
        img.src = imageList[0].data_url

        img.onload = () => {
          if (img.width < 500 || img.height < 500 || img.width !== img.height) {
            setNewPicture({
              error: "errors.error_file_format",
            })
          } else {
            setNewPicture(prev => ({
              ...prev,
              data: file,
              url: imageList[0].data_url,
              error: false,
            }))
          }
        }

        setNewPicture(prev => ({ ...prev, loading: false }))
      } else {
        setNewPicture(prev => ({ ...prev, loading: false, error: "error_api" }))
      }
    }
  })

  const onErrorFile = error => {
    if (error.acceptType) {
      setNewPicture({
        filename: null,
        data: null,
        loading: true,
        url: null,
        error: "errors.error_file_format",
      })
    }
  }

  return (
    <main>
      <section className={css(styles.section)}>
        <Link
          target="_blank"
          className={css(styles.link)}
          to={`${config.BASE_URL_APP}/en/${params.get("manual")}`}>{`${config.BASE_URL_APP}/en/${params.get("manual")}`}</Link>
        <p style={{ paddingTop: 10 }} className={css(styles.desc)}>
          {t("desc")}
        </p>
      </section>
      <section className={css(styles.section)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className={css(styles.subTitle)}>{t("subTitle")}</h1>
          <div className={css(styles.flex)}>
            <div className={css(styles.flexItem)}>
              <div>
                <UIInputWithRegister
                  theme={themeStyles}
                  dynamicStyle={[styles.input]}
                  label={t("name_product")}
                  labelDynamicStyle={[styles.label]}
                  errors={errors?.productName}
                  keyInput="productName"
                  setValue={setValue}
                  register={register}
                  placeholder="ES300"
                  maxLength={35}
                />
              </div>

              <div>
                <p className={css(styles.label)}>{t("parts_summary")}</p>

                {manual?.materialsData?.parts?.length > 0 ? (
                  manual?.materialsData?.parts?.map((part, index) => (
                    <p key={part?.translation + index} className={css(styles.smallText)}>
                      {`${part?.quantity?.toString()?.padStart(2, "0")}x ${part?.translation || "nc"}`}
                    </p>
                  ))
                ) : (
                  <>
                    {manual?.materialsData?.loading ? (
                      <div className={css(styles.containerSpinner)}>
                        <div className={css(styles.spinner)}>
                          <FadeLoader size={10} color={themeStyles.PRIMARY_COLOR || BLACK_COLOR} />
                        </div>

                        <p className={css(styles.smallText)}>{t("loading")}</p>
                      </div>
                    ) : (
                      <p className={css(styles.smallText)}>{t("no_part")}</p>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={css(styles.flexItem)}>
              <div>
                <UIInputWithRegister
                  theme={themeStyles}
                  dynamicStyle={[styles.inputTiny]}
                  label={t("nb_user")}
                  labelDynamicStyle={[styles.label]}
                  errors={errors?.nbUser}
                  keyInput="nbUser"
                  setValue={setValue}
                  type="number"
                  min={1}
                  max={9}
                  register={register}
                  placeholder="2"
                  showTextError={true}
                  errorTextPosition="row"
                />
              </div>

              <div>
                <p className={css(styles.label)}>{t("tools_summary")}</p>
                {manual?.materialsData?.tools?.length > 0 ? (
                  manual?.materialsData?.tools?.map((tool, index) => (
                    <p key={tool?.translation + index} className={css(styles.smallText)}>
                      {`${tool?.quantity?.toString()?.padStart(2, "0")}x ${tool?.translation || "nc"}`}
                    </p>
                  ))
                ) : (
                  <>
                    {manual?.materialsData?.loading ? (
                      <div className={css(styles.containerSpinner)}>
                        <div className={css(styles.spinner)}>
                          <FadeLoader size={10} color={themeStyles.PRIMARY_COLOR || BLACK_COLOR} />
                        </div>
                        <p className={css(styles.smallText)}>{t("loading")}</p>
                      </div>
                    ) : (
                      <p className={css(styles.smallText)}>{t("no_tool")}</p>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={css(styles.flexItem)}>
              <div>
                <UIInputWithRegister
                  theme={themeStyles}
                  dynamicStyle={[styles.inputTiny]}
                  label={t("time_product")}
                  labelDynamicStyle={[styles.label]}
                  errors={errors?.time}
                  keyInput="time"
                  type="number"
                  min={5}
                  max={240}
                  setValue={setValue}
                  register={register}
                  placeholder="35"
                  showTextError={true}
                  errorTextPosition="row"
                />
              </div>

              <div>
                <p style={{ paddingBottom: 5 }} className={css(styles.label)}>
                  {t("brand")}
                </p>
                <div style={{ maxWidth: 200 }}>
                  <UISelect
                    options={brandByIdBrand ?? []}
                    isDisabled={false}
                    onOptionChanged={brand => {
                      setBrandSelected(brand)
                      setValue("brandId", brand.value)
                    }}
                    menuPortalTarget={document.body}
                    outlined={false}
                    placeholder={t("brand_placeholder")}
                    value={brandSelected?.label ? brandSelected : null}
                    primaryColor={themeStyles?.PRIMARY_COLOR}
                    secondaryColor={themeStyles?.SECONDARY_COLOR}
                  />
                </div>
              </div>
            </div>
            <div className={css(styles.flexItem)}>
              <div>
                <div className={css(styles.containerThumbnailTitle)}>
                  <p className={css(styles.label)}>{t("img_product")}</p>
                  <UIHtmlTooltip
                    primarycolor={themeStyles.PRIMARY_COLOR}
                    placement="top"
                    title={t("errors.error_file_format")}
                    arrow
                  />
                </div>
                {(newPicture?.url || manual?.urlThumbnail) && !newPicture?.error && (
                  <div className={css(styles.containerImg)}>
                    <img
                      alt="thumbnail"
                      style={{ display: "block" }}
                      className={css(styles.thumbnail)}
                      src={newPicture?.url || manual?.urlThumbnail}
                    />
                  </div>
                )}
                <UIImgUpload
                  uploadBtnText={t("file_download")}
                  theme={themeStyles}
                  onChange={onChangeCoverImage}
                  image={newPicture?.url}
                  size="small"
                  type="button"
                  height={35}
                  width={140}
                  onError={onErrorFile}
                  labelDynamicStyle={styles.label}
                  btnColor={themeStyles?.SECONDARY_COLOR}
                  removeImage={() => setNewPicture(null)}
                />
                {newPicture?.error && <p className={css(styles.errorText)}>{t(newPicture.error)}</p>}
              </div>
            </div>
          </div>

          <UIButtonIcon
            dynamicStyle={[styles.btnSave, styles.btn]}
            label={t("save")}
            icon={<Save />}
            loading={loading}
            animationSuccess={saveSuccess}
            type="submit"
            disabled={
              errors?.time || errors?.nbUser || errors?.productName || errors?.brandId || newPicture?.error
            }
          />
        </form>
      </section>

      <section className={css(styles.section)}>
        <h1 className={css(styles.subTitle)}>{t("step_title")}</h1>
        <p className={css(styles.desc)}>{t("step_desc")}</p>
        <section className={css(styles.flexSteps)}>
          {manual?.stepsData?.length > 0 ? (
            manual?.stepsData?.map((step, index) => (
              <CardStepItem key={`step-${index}`} step={step} title={`${t("step")} ${index + 1}`} />
            ))
          ) : (
            <UIButton
              dynamicStyle={[styles.addBtn]}
              kind="primary"
              label={t("add_first_step")}
              themeStyle={themeStyles}
              onClick={addNewStep}
            />
          )}
        </section>
      </section>

      <section className={css(styles.section)}>
        <h1 className={css(styles.subTitle)}>{t("multi_lang_title")}</h1>
        <div className={css(styles.containerLang)}>
          {manual?.manualLanguagesByIdManual?.nodes?.map((lng, index) => (
            <React.Fragment key={lng?.langLocaleByIdLangLocale?.languageByIdLanguage?.code?.toLowerCase()}>
              {lng?.langLocaleByIdLangLocale?.languageByIdLanguage?.code?.toLowerCase() !== "fr" && (
                <CardLangItem
                  theme={themeStyles}
                  lng={lng}
                  key={lng?.langLocaleByIdLangLocale?.languageByIdLanguage?.code?.toLowerCase() + index}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </section>
      <footer className={css(styles.footer)}>
        <UIButton
          dynamicStyle={styles.btnSave}
          label={manual?.isPublished ? t("update_published") : t("published")}
          themeStyle={themeStyles}
          onClick={publishedManual}
          disabled={manual?.isPublished}
        />
        <UIButton
          dynamicStyle={styles.btnSave}
          label={t("show_preview")}
          onClick={() => {
            const url = `${config?.BASE_URL_APP}/${params?.get(
              "manual",
            )}?refreshAccess=${localStorage?.getItem("refresh_token")}`
            window.open(url, "_blank")
          }}
          themeStyle={themeStyles}
        />
      </footer>
    </main>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    link: {
      color: theme.PRIMARY_COLOR,
      fontSize: 14,
      fontFamily: "Roboto",
      fontWeight: "400",
    },
    section: {
      marginBottom: 45,
    },
    containerLang: {
      border: `1px solid ${theme.BORDER_INPUT_COLOR}`,
      width: 350,
      borderBottom: "none",
    },
    subTitle: {
      color: theme.PRIMARY_COLOR,
      fontWeight: "600",
      fontFamily: "Roboto",
      textTransform: "uppercase",
      fontSize: 16,
      margin: "0 0 16px",
    },
    thumbnail: {
      height: "auto",
      width: "auto",
      maxWidth: "100%",
      maxHeight: "100%",
      display: "block",
      objectFit: "contain",
    },
    containerImg: {
      height: 115,
      width: 115,
      boxSizing: "border-box",
      overflow: "hidden",
      background: WHITE,
      marginTop: 5,
      display: "flex",
      aspectRatio: "1/1",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 8,
      border: `2px solid${theme?.SECONDARY_COLOR}`,
    },
    containerSpinner: {
      display: "flex",
      alignItems: "center",
      gap: 5,
      height: 35,
      marginLeft: -8,
    },
    spinner: {
      transform: "scale(0.4)",
      width: 35,
      marginTop: 8,
    },
    containerThumbnailTitle: { display: "flex" },
    desc: {
      color: theme.PRIMARY_COLOR,
      fontWeight: "400",
      fontFamily: "Roboto",
      width: "60%",
      minWidth: 750,
      fontSize: 14,
      lineHeight: 1.5,
      margin: 0,
    },
    flex: {
      display: "flex",
      flexWrap: "wrap",
      gap: "3%",
      position: "relative",
      justifyContent: "space-between",
      zIndex: 1,
      "@media (max-width: 1200px)": {
        gap: 10,
      },
    },
    flexSteps: {
      display: "flex",
      flexWrap: "wrap",
      gap: 20,
      position: "relative",
      zIndex: 1,
      marginTop: 16,
    },
    label: {
      fontWeight: "600",
      fontFamily: "Roboto",
      fontSize: 12,
      color: theme.PRIMARY_COLOR,
      margin: 0,
    },
    input: { height: 35, marginTop: 5, color: theme?.PRIMARY_COLOR, fontSize: 13, maxWidth: 170 },
    inputTiny: {
      height: 35,
      marginTop: 5,
      maxWidth: 50,
      color: theme?.PRIMARY_COLOR,
      fontSize: 13,
      paddingRight: 3,
      paddingLeft: 3,
      textAlign: "center",
    },
    flexItem: {
      display: "flex",
      flexDirection: "column",
      boxSizing: "border-box",
      gap: 30,
      width: "calc(100%/4 - 30px)",
    },
    smallText: {
      fontWeight: "400",
      fontFamily: "Roboto",
      fontSize: 13,
      color: theme.PRIMARY_COLOR,
      margin: "8px 0 0",
    },
    btnSave: {
      height: 35,
      padding: "0px 10px",
      minWidth: 130,
      fontWeight: "400",
      fontSize: 13,
      background: theme?.PRIMARY_COLOR,
      color: WHITE,
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
      marginTop: 20,
    },
    errorText: {
      color: theme.ERROR_COLOR || ERROR_COLOR,
      margin: 0,
      fontSize: 13,
      paddingTop: 10,
      lineHeight: 1.3,
      fontFamily: "Roboto",
      fontWeight: "400",
      width: 250,
      "@media (max-width: 1400px)": {
        width: 230,
      },
    },
    addBtn: {
      minWidth: 130,
      fontSize: 13,
      height: 35,
      fontFamily: "Roboto",
      fontWeight: "400",
    },
    footer: {
      display: "flex",
      gap: 10,
    },
  })

WidgetManager.propTypes = {
  manual: PropTypes.object,
  manualSteps: PropTypes.array,
  updateData: PropTypes.func,
  fetchBrandsByLabel: PropTypes.func,
  brandByIdBrand: PropTypes.array,
  brandsByLabelDb: PropTypes.array,
  materialsByManual: PropTypes.object,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  publishedManual: PropTypes.func,
  saveSuccess: PropTypes.bool,
  themeStyles: PropTypes.object,
  addNewStep: PropTypes.func,
}
