import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React from "react"
import { Lock, User } from "../../assets/svgr"
import { BLACK_COLOR, ERROR_COLOR, NEUTRAL_MEDIUM, WHITE } from "../../styles/colors"

export const UIInputWithRegister = ({
  type,
  onKeyDown = null,
  colorIcon = BLACK_COLOR,
  disabled = false,
  placeholder,
  setValue,
  autofocus = false,
  min,
  iconDirection = "right",
  icon = false,
  label,
  value,
  labelDynamicStyle,
  dynamicStyle,
  theme,
  maxLength,
  keyInput,
  register,
  errors,
  showTextError = false,
  errorTextPosition = "bottom",
  ...props
}) => {
  const styles = getStyles({ errors, iconDirection, theme, showTextError, errorTextPosition })

  const iconChoice = choiceIcon => {
    switch (choiceIcon) {
      case "lock":
        return <Lock color={colorIcon} />
      case "user":
        return <User color={colorIcon} />
      default:
        break
    }
  }

  return (
    <>
      {!icon && (
        <>
          {label && (
            <label className={css(styles.label, labelDynamicStyle)} htmlFor={keyInput}>
              {label}
            </label>
          )}
          <div className={css(styles.containerInput)}>
            <input
              {...register(keyInput)}
              placeholder={placeholder}
              value={value}
              type={type}
              min={min}
              id={keyInput ?? ""}
              maxLength={maxLength}
              onKeyDown={onKeyDown}
              style={{ marginTop: label ? 5 : 0 }}
              autoFocus={autofocus}
              onChange={e => setValue(keyInput, e.target.value, { shouldValidate: true })}
              className={css(styles.input, dynamicStyle)}
              disabled={disabled}
              {...props}
            />
            {showTextError && <p className={css(styles.errorText)}>{errors?.message}</p>}
          </div>
        </>
      )}
      {icon && (
        <>
          {label && (
            <label className={css(styles.label, labelDynamicStyle)} htmlFor={keyInput}>
              {label}
            </label>
          )}
          <div className={css(styles.containerInput)}>
            <input
              {...register(keyInput)}
              placeholder={placeholder}
              value={value}
              type={type}
              onKeyDown={onKeyDown}
              min={min}
              id={keyInput ?? ""}
              autoFocus={autofocus}
              onChange={e => setValue(keyInput, e.target.value, { shouldValidate: true })}
              className={css(styles.inputWithIcon, dynamicStyle)}
              disabled={disabled}
              {...props}
            />

            <div
              className={css(styles.containerIcon)}
              style={{
                right: iconDirection === "right" ? 10 : null,
                left: iconDirection === "left" ? 10 : null,
              }}>
              {iconChoice(icon)}
            </div>
          </div>
        </>
      )}
    </>
  )
}

const getStyles = ({ errors, iconDirection, theme, showTextError, errorTextPosition }) =>
  StyleSheet.create({
    containerInput: {
      position: "relative",
      boxSizing: "border-box",
      display: showTextError ? "flex" : "block",
      flexDirection: showTextError && errorTextPosition === "bottom" ? "column" : "row",
      gap: 10,
      alignItems: "center",
    },
    label: {
      fontSize: 10,
      fontWeight: 300,
      margin: 0,
    },
    errorText: {
      color: theme?.ERROR_COLOR || ERROR_COLOR,
      margin: 0,
      fontSize: 13,
      paddingTop: 5,
      lineHeight: 1.3,
      fontFamily: "Roboto",
      fontWeight: "400",
    },
    input: {
      width: "100%",
      height: 40,
      fontFamily: "Roboto",
      backgroundColor: WHITE,
      boxSizing: "border-box",
      paddingLeft: 10,
      paddingRight: 10,
      border: errors
        ? `1px solid ${theme.ERROR_COLOR || ERROR_COLOR}`
        : `1px solid ${theme.BORDER_INPUT_COLOR || NEUTRAL_MEDIUM}`,
      borderRadius: 5,
      color: errors ? theme.ERROR_COLOR || ERROR_COLOR : null,
      "::placeholder": {
        fontWeight: "400",
        opacity: 0.6,
      },
      ":disabled": {
        opacity: "0.6",
      },
      ":focus": {
        outline: `1.5px solid ${theme?.PRIMARY_COLOR}`,
      },
    },
    inputWithIcon: {
      width: "100%",
      boxSizing: "border-box",
      height: 40,
      fontFamily: "Roboto",
      backgroundColor: WHITE,
      border: errors
        ? `0.5px solid ${theme.ERROR_COLOR || ERROR_COLOR}`
        : `1px solid ${theme.BORDER_INPUT_COLOR || NEUTRAL_MEDIUM}`,
      padding: iconDirection === "right" ? "0px 35px 0px 15px" : "0px 15px 0px 35px",
      borderRadius: 5,
      color: errors ? theme.ERROR_COLOR || ERROR_COLOR : null,
      "::placeholder": {
        fontWeight: "600",
        opacity: 0.25,
        textAlign: "center",
      },
      ":focus": {
        outline: `1.5px solid ${theme?.PRIMARY_COLOR}`,
      },
    },
    containerIcon: {
      position: "absolute",
      margin: 0,
      top: 0,
      right: 0,
      display: "flex",
      height: 40,
      borderRadius: 5,
      zIndex: 2,
      width: "fit-content",
      justifyContent: "center",
      alignItems: "center",
    },
    btnIcon: {
      background: "transparent",
      border: "none",
      marginRight: 5,
    },
    btnClose: {
      border: "none",
      background: "transparent",
      position: "absolute",
      top: 11,
      right: 5,
      width: "fit-content",
    },
  })

UIInputWithRegister.propTypes = {
  showTextError: PropTypes.bool,
  errorTextPosition: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(["text", "password", "email", "tel", "date", "number"]),
  placeholder: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  valid: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  setValue: PropTypes.func,
  textBtn: PropTypes.string,
  enterkeyhint: PropTypes.oneOf(["enter", "done", "go", "next", "previous", "search", "send"]),
  onKeyDown: PropTypes.func,
  autofocus: PropTypes.bool,
  icon: PropTypes.string,
  button: PropTypes.bool,
  keyInput: PropTypes.string,
  kindBtn: PropTypes.string,
  typeBtn: PropTypes.func,
  iconDirection: PropTypes.string,
  inputId: PropTypes.string,
  fullHeight: PropTypes.bool,
  onClickIcon: PropTypes.func,
  eyeActive: PropTypes.bool,
  labelDynamicStyle: PropTypes.array,
  dynamicStyle: PropTypes.array,
  maxLength: PropTypes.number,
  close: PropTypes.bool,
  onClose: PropTypes.func,
  colorIcon: PropTypes.string,
  register: PropTypes.func,
  min: PropTypes.number,
  theme: PropTypes.object,
}
