import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { UIButton, UISelect } from "../../components/UI"
import { BLACK_COLOR, NEUTRAL_DARK, WHITE } from "../../styles/colors"

const WidgetAnimationItem = ({ dataAnimate, changeDataAnimate, animationModes, animatesAvailable }) => {
  const { themeStyles } = useTheme()
  const { t } = useTranslation("model3DPage")

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])

  const repeatChoices = useMemo(
    () => ["_1", "_2", "_3", "_4", "_5", "_6", "_7", "_8", "_9", "_10", "INFINITY"],
    [],
  )

  useEffect(() => {
    if (dataAnimate.name) {
      if (!dataAnimate?.repeat) changeDataAnimate("repeat", "_1")
      if (!dataAnimate?.idAnimationMode) {
        changeDataAnimate("idAnimationMode", animationModes[0]?.id)
        changeDataAnimate("animationModeLabel", animationModes[0]?.label)
      }
    }
  }, [animatesAvailable, dataAnimate])

  return (
    <div>
      <h1 className={css(styles.label)}>{t("pois_animate")}</h1>
      {dataAnimate && (
        <div className={css(styles.flexColumn)}>
          {animatesAvailable?.length > 0 ? (
            <div>
              <label className={css(styles.font600)} htmlFor="animate">
                {t("animate_name")}
              </label>

              <div style={{ width: "65%", minWidth: 185 }}>
                <UISelect
                  options={[
                    { value: "placeholder", label: t("placeholder_animation_name") },
                    ...(animatesAvailable?.map(anim => ({
                      value: anim,
                      label: anim,
                    })) || []),
                  ]}
                  isDisabled={false}
                  menuPortalTarget={document.body}
                  outlined={false}
                  value={
                    dataAnimate?.name
                      ? { value: dataAnimate.name, label: dataAnimate.name }
                      : { value: "placeholder", label: t("placeholder_animation_name") }
                  }
                  onOptionChanged={option => {
                    if (option?.value === "placeholder") {
                      changeDataAnimate("name", null)
                    } else {
                      changeDataAnimate("name", option?.value)
                    }
                  }}
                  primaryColor={themeStyles?.PRIMARY_COLOR}
                  secondaryColor={themeStyles?.SECONDARY_COLOR}
                />
              </div>

              {dataAnimate?.name && (
                <div style={{ gap: 20, marginTop: 10 }} className={css(styles.flexRow)}>
                  <section style={{ width: "65%" }}>
                    <p className={css(styles.font600)}>{t("type")}</p>
                    <div className={css(styles.flexRow)}>
                      {animationModes?.map(item => (
                        <UIButton
                          key={item.id}
                          label={item.label}
                          onClick={() => {
                            changeDataAnimate("idAnimationMode", item.id)
                            changeDataAnimate("animationModeLabel", item.label)
                          }}
                          dynamicStyle={[
                            styles.btn,
                            dataAnimate?.idAnimationMode === item.id && styles.isSelected,
                          ]}
                        />
                      ))}
                    </div>
                  </section>
                  <section>
                    <p className={css(styles.font600)}>{t("repeat")}</p>
                    <select
                      value={dataAnimate?.repeat}
                      onChange={e => changeDataAnimate("repeat", e.target.value)}
                      className={css(styles.select)}>
                      {repeatChoices?.map(number => (
                        <option key={number} value={number}>
                          {number === "INFINITY" ? "∞" : number.substring(1)}
                        </option>
                      ))}
                    </select>
                  </section>
                </div>
              )}
            </div>
          ) : (
            <p className={css(styles.font600)}>{t("no_animate")}</p>
          )}
        </div>
      )}
    </div>
  )
}

WidgetAnimationItem.propTypes = {
  dataAnimate: PropTypes.object,
  changeDataAnimate: PropTypes.func,
  animationModes: PropTypes.array,
  animatesAvailable: PropTypes.array,
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    label: {
      color: theme.PRIMARY_COLOR,
      fontFamily: "Roboto",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: "600",
      margin: 0,
      paddingBottom: 10,
    },
    flexRow: { display: "flex", flexDirection: "row" },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    isSelected: {
      backgroundColor: theme?.SECONDARY_COLOR ?? BLACK_COLOR,
      color: WHITE,
      borderColor: theme?.SECONDARY_COLOR ?? BLACK_COLOR,
    },
    btn: {
      width: "50%",
      textTransform: "capitalize",
      minHeight: 30,
      borderStyle: "solid",
      borderColor: NEUTRAL_DARK,
      borderWidth: 1,
      backgroundColor: WHITE,
      color: NEUTRAL_DARK,
      fontFamily: "Open Sans",
      fontSize: 12,
      padding: "0px 10px",
      fontWeight: "400",
      borderRadius: 0,
    },
    font600: {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "22px",
      padding: "5px 0 0",
      margin: "0px 0px 5px",
      color: theme.PRIMARY_COLOR || BLACK_COLOR,
    },
    select: {
      width: "100%",
      textAlign: "left",
      height: 30,
      borderRadius: 4,
      border: `1px solid ${NEUTRAL_DARK}`,
      backgroundColor: theme.INPUT_BACKGROUND_COLOR,
      color: theme.PRIMARY_COLOR,
      fontFamily: "Open Sans",
      fontSize: 13,
      fontStyle: "normal",
      fontWeight: "400",
      position: "relative",
      padding: "0px 5px",
    },
  })
export default WidgetAnimationItem
