import { yupResolver } from "@hookform/resolvers/yup"
import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { useLayoutEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Modal from "react-modal"
import { useSearchParams } from "react-router-dom"
import * as yup from "yup"
import { UIButton, UIImgUpload, UIInputWithRegister, UISelect } from "../../components/UI"
import UIHtmlTooltip from "../../components/UI/UIHtmlTooltip"
import { BLACK_COLOR, ERROR_COLOR, WHITE } from "../../styles/colors"

const staticStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80dvw",
    maxWidth: 750,
    maxHeight: "90dvh",
    border: "none",
    padding: 50,
    borderRadius: 0,
    boxSizing: "border-box",
  },
  overlay: { background: "rgba(0, 22, 33, 0.70)" },
}
export const ManualModal = ({
  isOpen,
  closeModal,
  brandsDb,
  fetchBrandsByLabel,
  onSave,
  loadingCreate,
  themeStyles,
}) => {
  const { t } = useTranslation("manualsPage")
  const [newFile, setNewFile] = useState()
  const [brandSelected, setBrandSelected] = useState({ value: "", label: "" })
  const [params] = useSearchParams()

  // init style ----------------------------------------------------------------
  const styles = useMemo(() => getStyles({ theme: themeStyles }), [themeStyles])
  const customStyles = useMemo(() => ({ ...staticStyles, ...styles }), [styles])

  const schema = yup.object().shape({
    productName: yup.string().required(),
    nbUsers: yup.number().min(1, t("modal.errors.error_nb_user")).max(9, t("modal.errors.error_nb_user")),
    time: yup.number().min(5, t("modal.errors.error_time")).max(240, t("modal.errors.error_time")),
    brandId: yup.string().required(),
  })

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: "onChange",
    mode: "onSubmit",
    resolver: yupResolver(schema),
  })

  // functions -----------------------------------------------------------------
  const close = () => {
    resetData()
    closeModal()
  }

  const resetData = () => {
    setValue("productName", "")
    setValue("nbUsers", "")
    setValue("time", "")
    setValue("brandId", "")
    setBrandSelected({ value: "", label: "" })
    setNewFile({
      filename: null,
      data: null,
      loading: true,
      url: null,
      error: false,
    })
  }
  const onSubmit = data => {
    onSave({ ...data, file: newFile })
    setTimeout(() => {
      resetData()
    }, 2000)
  }

  const onChangeCoverImage = async imageList => {
    setNewFile({
      filename: null,
      data: null,
      loading: true,
      url: null,
      error: false,
    })

    const file = imageList[0].file
    if (file) {
      const filename = imageList[0].file.name

      setNewFile(prev => ({ ...prev, filename }))

      // test width == height
      const img = new Image()
      img.src = imageList[0].data_url

      img.onload = () => {
        if (img.width < 500 || img.height < 500 || img.width !== img.height) {
          setNewFile(prev => ({ ...prev, error: "modal.errors.error_file_format_manual" }))
        } else {
          setNewFile(prev => ({
            ...prev,
            data: file,
            url: imageList[0].data_url,
            error: false,
          }))
        }
      }

      setNewFile(prev => ({ ...prev, loading: false }))
    } else {
      setNewFile(prev => ({ ...prev, loading: false, error: "error_api" }))
    }
  }

  const onErrorFile = error => {
    if (error.acceptType) {
      setNewFile({
        filename: null,
        data: null,
        loading: true,
        url: null,
        error: "modal.errors.error_file_format_manual",
      })
    }
  }

  useLayoutEffect(() => {
    fetchBrandsByLabel()
  }, [])

  useLayoutEffect(() => {
    if (params.get("brand") && brandsDb && !brandSelected.value) {
      const item = brandsDb.find(brand => brand.value === params.get("brand"))
      setBrandSelected({ label: item?.label, value: item?.value })
      setValue("brandId", item?.value)
    }
  }, [params.get("brand"), brandsDb])

  return (
    <Modal
      onRequestClose={close}
      appElement={document.getElementById("root")}
      isOpen={isOpen}
      style={customStyles}>
      <h2 className={css(styles.title)}>{t("modal.title")}</h2>
      <p className={css(styles.subTitle)}>{t("modal.subTitle")}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className={css(styles.containerForm)}>
          <div className={css(styles.leftForm)}>
            <div className={css(styles.containerData)}>
              <UIInputWithRegister
                theme={themeStyles}
                label={t("modal.name_product")}
                dynamicStyle={[styles.inputSmall]}
                errors={errors?.productName}
                type="text"
                setValue={setValue}
                keyInput="productName"
                maxLength={35}
                register={register}
                labelDynamicStyle={[styles.label]}
                placeholder="ES300"
              />
            </div>
            <div className={css(styles.containerData)}>
              <UIInputWithRegister
                theme={themeStyles}
                label={t("modal.nb_user")}
                dynamicStyle={[styles.inputTiny]}
                errors={errors?.nbUsers}
                setValue={setValue}
                type="number"
                min={1}
                max={9}
                keyInput="nbUsers"
                register={register}
                labelDynamicStyle={[styles.label]}
                placeholder="2"
                showTextError={true}
                errorTextPosition="row"
              />
            </div>
            <div className={css(styles.containerData)} style={{ width: "80%" }}>
              <p className={css(styles.label)}>{t("modal.brand")}</p>
              <UISelect
                options={[{ value: "placeholder", label: t("modal.brand_placeholder") }, ...(brandsDb ?? [])]}
                isDisabled={false}
                onOptionChanged={brand => {
                  if (brand?.value === "placeholder") {
                    setBrandSelected(null)
                    setValue("brandId", null)
                  } else {
                    setBrandSelected(brand)
                    setValue("brandId", brand.value)
                  }
                }}
                inputHeight={35}
                outlined={false}
                menuPlacement="auto"
                value={
                  brandSelected?.value
                    ? brandSelected
                    : { value: "placeholder", label: t("modal.brand_placeholder") }
                }
                primaryColor={themeStyles?.PRIMARY_COLOR}
                secondaryColor={themeStyles?.SECONDARY_COLOR}
              />
            </div>
          </div>
          <div className={css(styles.rightForm)}>
            <div className={css(styles.containerData)}>
              <div className={css(styles.containerThumbnailTitle)}>
                <p className={css(styles.label)}>
                  {t("modal.img_product")}
                  <UIHtmlTooltip
                    placement="top"
                    title={t("modal.errors.error_file_format_manual")}
                    arrow
                    primarycolor={themeStyles.PRIMARY_COLOR}
                  />
                </p>
              </div>
              {newFile?.url && (
                <div
                  style={{
                    height: 110,
                    width: 110,
                    padding: 3,
                    boxSizing: "border-box",
                    overflow: "hidden",
                    background: WHITE,
                    marginBottom: 5,
                    border: `2px solid${themeStyles?.SECONDARY_COLOR}`,
                  }}>
                  <img style={{ display: "block" }} className={css(styles.thumbnail)} src={newFile?.url} />
                </div>
              )}

              <UIImgUpload
                uploadBtnText={t("modal.file_download")}
                theme={themeStyles}
                onChange={onChangeCoverImage}
                onError={onErrorFile}
                image={newFile?.url}
                width={159}
                height={35}
                disabled={false}
                dynamicStyle={[styles.btn]}
                type="button"
                btnColor={themeStyles?.SECONDARY_COLOR || BLACK_COLOR}
                removeImage={() => setNewFile(null)}
              />
              {newFile?.error && <p className={css(styles.errorText)}>{t(newFile.error)}</p>}
            </div>

            <div className={css(styles.containerData)}>
              <UIInputWithRegister
                theme={themeStyles}
                label={t("modal.time_product")}
                labelDynamicStyle={[styles.label]}
                dynamicStyle={[styles.inputTiny]}
                errors={errors?.time}
                setValue={setValue}
                keyInput="time"
                type="number"
                min={5}
                max={240}
                placeholder="35"
                register={register}
                showTextError={true}
                errorTextPosition="row"
              />
            </div>
          </div>
        </section>

        <footer className={css(styles.containerBtn)}>
          <UIButton
            dynamicStyle={[styles.btn]}
            label={t("modal.save")}
            themeStyle={themeStyles}
            kind="primary"
            type="submit"
            loading={loadingCreate}
            disabled={
              !brandSelected ||
              errors?.productName ||
              errors?.brandId ||
              errors?.nbUsers ||
              errors?.time ||
              !newFile?.data
            }
          />
          <UIButton
            dynamicStyle={[styles.btn]}
            label={t("modal.cancel")}
            themeStyle={themeStyles}
            kind="secondary"
            onClick={close}
            type="button"
          />
        </footer>
      </form>
    </Modal>
  )
}

const getStyles = ({ theme }) =>
  StyleSheet.create({
    title: {
      fontWeight: "600",
      color: theme.PRIMARY_COLOR,
      fontSize: 26,
      fontFamily: "Roboto",
      textTransform: "uppercase",
      flexShrink: 0,
      margin: "0px 0px 16px 0px",
    },
    subTitle: {
      fontWeight: "400",
      color: theme.PRIMARY_COLOR,
      fontSize: 16,
      fontFamily: "Roboto",
      maxWidth: "80%",
      marginBottom: 30,
    },
    containerBtn: {
      display: "flex",
      gap: 10,
      marginTop: 60,
      width: "100%",
    },
    containerData: {
      marginBottom: 15,
      display: "flex",
      flexDirection: "column",
      gap: 8,
    },
    containerForm: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 20,
    },
    containerThumbnailTitle: { display: "flex" },
    btn: {
      fontWeight: "400",
      fontFamily: "Roboto",
      fontSize: 15,
      height: 40,
      padding: "0px 20px",
      minWidth: 138,
      marginTop: 0,
    },
    label: {
      fontWeight: "600",
      fontFamily: "Roboto",
      fontSize: 14,
      color: theme.PRIMARY_COLOR,
      margin: 0,
    },
    inputSmall: {
      display: "flex",
      width: 170,
      height: 35,
      fontSize: 14,
      alignItems: "center",
      flexShrink: 0,
      color: theme.PRIMARY_COLOR,
      margin: 0,
    },
    inputTiny: {
      height: 35,
      maxWidth: 50,
      color: theme?.PRIMARY_COLOR,
      fontSize: 14,
      paddingRight: 3,
      paddingLeft: 3,
      textAlign: "center",
      margin: 0,
    },
    errorText: {
      color: theme?.ERROR_COLOR || ERROR_COLOR,
      margin: 0,
      fontSize: 13,
      paddingTop: 5,
      lineHeight: 1.3,
      fontFamily: "Roboto",
      fontWeight: "400",
    },
    thumbnail: {
      height: 100,
      width: 100,
    },
  })

ManualModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  brandsDb: PropTypes.array,
  fetchBrandsByLabel: PropTypes.func,
  onSave: PropTypes.func,
  loadingCreate: PropTypes.bool,
  themeStyles: PropTypes.object,
}
