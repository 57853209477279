import { css, StyleSheet } from "aphrodite"
import PropTypes from "prop-types"
import React, { Suspense, useEffect, useMemo, useState } from "react"
import { CloseSmall } from "../../assets/svgr"
import { useTheme } from "../../components/Provider/ThemeProvider"
import { checkEnv } from "../../lib"
import { BLACK_COLOR, NEUTRAL_DARK, WHITE } from "../../styles/colors"

export const WidgetPartChildStep = ({ id, remove, onChange, quantity, imgSrc }) => {
  const [label, setLabel] = useState()

  // init style ----------------------------------------------------------------
  const { themeStyles } = useTheme()
  const styles = useMemo(() => getStyles({ theme: themeStyles, quantity }), [themeStyles, quantity])
  const config = useMemo(() => checkEnv(), [])

  const fetchTrad = async () => {
    const response = await fetch(`${config.BASE_URL}/parts/${id}/translations`)
    if (response.ok) {
      const trads = await response.json()
      if (trads.label.en) {
        setLabel(trads.label.en)
      }
    }
  }

  useEffect(() => {
    if (id) {
      fetchTrad()
    }
  }, [id])

  return (
    <React.Fragment>
      <Suspense>
        {label && (
          <div className={css(styles.itemParts)}>
            <button
              aria-label="close-button"
              onClick={remove}
              className={css(styles.primaryRoundedBtn)}
              type="button">
              <CloseSmall color={WHITE} />
            </button>
            <input
              className={css(styles.input)}
              type="number"
              min={1}
              placeholder="10"
              inputMode="numeric"
              defaultValue={quantity}
              onChange={e => onChange(e.target.value)}
            />
            <img alt="image" height={"100%"} width={"100%"} className={css(styles.img)} src={imgSrc} />
            <p className={css(styles.font400)}>{label}</p>
          </div>
        )}
      </Suspense>
    </React.Fragment>
  )
}
const getStyles = ({ theme, quantity }) =>
  StyleSheet.create({
    itemParts: {
      display: "flex",
      alignItems: "center",
      gap: 5,
      margin: 0,
      paddingBottom: 5,
    },
    input: {
      width: 35,
      height: 30,
      fontSize: 12,
      fontWeight: "400",
      borderRadius: 4,
      color: theme?.PRIMARY_COLOR || BLACK_COLOR,
      boxSizing: "border-box",
      textAlign: "center",
      border: `1px solid ${Number(quantity) > 0 ? NEUTRAL_DARK : theme?.ERROR_COLOR || ERROR_COLOR}`,
      "::placeholder": {
        color: NEUTRAL_DARK,
      },
      ":focus": {
        outline: `1.5px solid ${theme?.PRIMARY_COLOR}`,
      },
      ":active": {
        outline: `1.5px solid ${theme?.PRIMARY_COLOR}`,
      },
    },
    img: {
      maxWidth: 40,
      height: "auto",
    },
    primaryRoundedBtn: {
      background: theme?.PRIMARY_COLOR,
      borderRadius: "50%",
      border: "none",
      width: 18,
      height: 18,
      gap: 3,
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ":hover": {
        opacity: 0.6,
      },
    },
    font400: {
      fontSize: 12,
      fontWeight: "400",
      color: theme?.PRIMARY_COLOR,
      textTransform: "capitalize",
      margin: 0,
    },
  })
WidgetPartChildStep.propTypes = {
  onChange: PropTypes.func,
  quantity: PropTypes.number,
  imgSrc: PropTypes.string,
  remove: PropTypes.func,
  id: PropTypes.string,
}
